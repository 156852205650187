#help-box {
  .tooltip-inner {
    max-width: 300px;
    font-size: 18px;
    padding: 15px;
  }

  button {
    color: black;
    border: none;
    font-weight: 600;
    margin-top: 10px;
    width: 80%;
    height: 40px;
    border-radius: 35px;
    outline: none;
  }
}
