body#Club-View {
  background-color: #fafafa;
  color: black !important;
  font-family: Montserrat;

  #content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  #main-container {
    .nav-item {
      background-color: gray;
    }

    .table {
      color: black !important;
    }

    .table-striped tbody tr:nth-of-type(2n + 1) {
      background-color: #fafafa !important;
    }
  }
}
