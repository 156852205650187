body#organizations-and-clubs-page {
  background-color: #fafafa;
  color: black !important;
  font-family: Montserrat;
  #content-container {
    max-width: 1000px;
    .header {
      color: #469a88;
      font-weight: bold;
      font-size: calc(min(6vw, 30px));
      font-style: normal;
      line-height: 44px;
      margin-top: 30px;
    }

    .box {
      background: #fafafa;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      padding: 20px;
      margin-top: 10px;

      .card-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin: 10px 5px;

        img {
          border-radius: 50%;
        }
        .text {
          margin-top: 5px;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          color: #00bc8c;
        }
      }
    }

    #search-by-fide-id {
      .wrapper {
        display: flex;
        width: 50%;
      }
      input {
        width: clamp(300px, 60vw, 800px);
        border: solid 1px #b2b2b2;
        border-radius: 0.25rem 0 0 0.25rem;
      }
      button {
        background-color: #469a88;
        border-radius: 0 0.25rem 0.25rem 0;
      }
    }
  }
}
