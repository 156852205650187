
.content{
    display: grid;
    grid-auto-flow: column;
    grid-gap: 30px;
    gap: 30px;
    padding: 20px;
.card {
    background: #f4f4f4;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0px 0px 90px 0px;
    width: 250px;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-top: 30px;
    }
  
    .image {
      border-radius: 50%;
      position: absolute;
      top: -30px;
    }
  
    .name {
      font-weight: bold;
      margin-top: 70px;
      font-size: 18px;
      text-align: center;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  
    .button {
      cursor: pointer;
      background-color: #469a88;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border: none;
      width: 140px;
      height: 40px;
      color: white;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 40px;
    }
  }
  