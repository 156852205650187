.input-add-form {
  .wrapper {
    display: flex;
  }
  input {
    width: clamp(300px, 60vw, 800px);
    border: solid 1px #b2b2b2;
    border-radius: 0.25rem 0 0 0.25rem;
    padding: 10px;
  }
  button {
    background-color: #469a88;
    border-radius: 0 0.25rem 0.25rem 0;
    padding: 5px;

    i {
      line-height: 30%;
      font-size: 30px;
    }
  }
}
