#miniboards {
  margin-top: 20px;
}

#board {
  width: 250px;
  .info {
    display: flex;
    justify-content: space-between;

    .name {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .outcome {
    position: absolute;
    top: 40%;
    left: 40%;
    background-color: white;
    font-weight: 600;
    z-index: 2;
    padding: 5px;
    max-width: 100px;
  }
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.board {
  position: relative;
}
.pagination {
  margin-top: 20px;
}
#pairingnumber {
  height: 26px;
  text-align: center;
  top: 0;
  left: 0;
  font-weight: 600;
  width: 25px;
  z-index: 10;
  background-color: transparent;
  padding: px;
}
.toogle{
  float: right;
  button{
    width:1.7vw;
    height: 3.5vh;
  }
}
@media only screen and (max-width: 800px) {
  #miniboards {
    #row {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
  #pairingnumber {
    top: 0;
    left: 10%;
    background-color: transparent;
  }
}
