#Account-Create table input {
    width: 100%;
}

#Account-Create #addButton {
    margin-top: -4px;
}

.header {
    color: #469a88;
    font-weight: bold;
    font-size: calc(min(6vw, 40px));
    font-style: normal;
    line-height: 44px;
    margin: 30px 0px 20px 0px;
}

body#Account-Create {
    background-color: #fafafa;
    color: black !important;
    font-family: Montserrat;
}

body#Account-Create .nav-item {
    background-color: gray;
}

body#Account-Create .table {
    color: black !important;
}

body#Account-Create .table-striped tbody tr:nth-of-type(2n + 1) {
    background-color: #fafafa !important;
}
#Account-Create .table-header{
    font-size: 15px!important;
    font-weight: 800!important;
    font-family: Montserrat!important;
}
#Account-Create .table-cell{
    font-size: 16px;
}

#Account-Create .table-cell > a{
color: #00bc8c;
font-family: Montserrat!important;
}

#Account-Create #content-container {
  max-width: 80%;
}