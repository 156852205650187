#Game-View {
  background-color: #fafafa;
  color: black;
}

#Game-View #content-container {
  max-width: 1200px;
}
#Game-View div.cg-wrap,
#Game-View cg-container {
  width: 90vh; /* Firefox mobile compat */
  width: calc(min(78vw, 73vh));
  height: 90vw;
  height: calc(min(78vw, 73vh));
  z-index: -1;
}
#Game-View #move-div {
  max-height: 90vh; /* Firefox mobile compat */
  max-height: min(525px, 90vh);
  min-width: 200px;
  overflow-y: auto;
  font-size: 13pt;
  padding-right: 15px;
}
#Game-View #move-table {
  margin-top: 40px;
}
#Game-View #move-table td {
  padding-left: 3px;
  padding-right: 12px;
}
#Game-View #move-table td.move {
  cursor: pointer;
}
#Game-View .highlight-blue {
  box-shadow: inset 0 0 2px 2px blue;
}
#Game-View #game-result-div {
  font-size: 24pt;
}
#Game-View #player-links {
  max-height: 90vh;
  max-height: min(525px, 90vh);
}
#Game-View #controls div {
  text-align: center;
  cursor: pointer;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
#Game-View #controls {
  padding-left: 5px;
  z-index: 1;
}
#Game-View .move-cell {
  cursor: pointer;
}
#Game-View #game-result {
  font-size: 24px;
}
#Game-View #player-links div {
  font-size: 1.2rem;
  font-weight: bold;
}
#Game-View .play-box {
  padding-right: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  max-width: calc(min(78vw, 73vh));
}

#Game-View .play-box .user-box {
  display: flex;
  align-items: center;
  height: 50px;
}

#Game-View .play-box .user-box .user-info {
  display: flex;
  margin: 25px 20px 10px 20px;
}

#Game-View .tournament-link {
  position: absolute;
  background-color: #fafafa;
}

#Game-View .play-box .user-box .user-info .name {
  display: flex;
  margin-left: 15px;
}
.pointer {
  cursor: pointer;
}
.box {
  justify-content: space-between;
}
#Game-View .flex-fill.p-3 button {
  width: 100%;
}
#Game-View #controls {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
#Game-View #move-div {
  margin-top: 20px;
}
#Game-View #controls div {
  margin-bottom: 5px;
}

@media screen and (max-width: 1080px) and (min-width: 500px) {
  #Game-View div.cg-wrap,
  #Game-View cg-container {
    width: calc(min(60vw, 60vh));
    height: calc(min(60vw, 60vh));
  }
  #Game-View .play-box {
    padding-right: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    max-width: calc(min(60vw, 60vh));
  }
}
@media screen and (max-width: 900px) {
  #Game-View #move-div {
    min-width: 0;
    max-height: 150px !important;
  }
}
@media screen and (max-width: 500px) {
  #Game-View div.cg-wrap,
  #Game-View cg-container {
    width: calc(min(78vw, 73vh));
    height: calc(min(78vw, 73vh));
  }
  .box {
    justify-content: center;
  }
  #Game-View .tournament-link a {
    margin-left: 15px;
  }
  #Game-View .play-box .user-box .user-info .name a {
    font-size: 17px;
    margin-left: -8px;
  }
  #Game-View #controls {
    margin-top: 15px;
    margin-bottom: 20px;
  }
  #Game-View #controls div {
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

#outcome-popup .modal-content {
  background-color: #fafafa;
}