#heading {
  font-size: calc(min(3vw, 18px));
  color: #469a88;
  font-family: Montserrat;
}

.content {
  margin-top: 10px;
}

.action {
  margin-top: 30px !important;
}

.select {
  .inputs {
    margin: 10px 0px;
  }
}

.box {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 20px;
  max-height: 400px;
}

.list {
  max-height: 300px;
  overflow: scroll;
}

.absolute-right {
  position: absolute !important;
  right: 0;
  top: 10px;
}

.error {
  color: red !important;
}

.mr-sm {
  margin-right: 5px !important;
}

.wrapper {
  font-family: Montserrat;
  color: black;
  width: calc(min(90vw, 1200px));

  .round-starting-time {
    margin-top: 15px;
    font-weight: bold;
  }

  .header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 25px 0px 10px 0px;

    .header {
      color: #469a88;
      font-weight: bold;
      font-size: calc(min(6vw, 30px));
    }
    .icon {
      .tick {
        width: 20px;
      }
    }
  }

  .banner {
    position: relative;
    .pp {
      width: 15%;
      position: absolute;
      top: 65%;
      left: 42.5%;
    }
    .pp-custom {
      position: absolute;
      top: 65%;
      left: calc(min(40vw, 525px));
      height: calc(min(15vw, 150px));
      width: calc(min(15vw, 150px));
      border-radius: 50%;
    }

    .banner-pic {
      width: 100%;
      max-height: 300px;
      object-fit: cover;
    }
  }

  .name-container {
    position: relative;
    margin-top: calc(min(8vw, 80px));
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    .name {
      font-size: calc(min(5vw, 48px));
      font-weight: bold;
      line-height: calc(min(6vw, 50px));
      max-width: 800px;
    }
    .organiser {
      font-size: calc(min(3vw, 20px));
      margin-top: 10px;
      span {
        color: #469a88;
      }
    }
    .icon {
      position: absolute;
      top: 10px;
      right: 0;
      img {
        width: calc(min(5vw, 30px));
        cursor: pointer;
      }
    }
  }
  .about {
    margin-top: 20px;
    font-size: calc(min(3vw, 20px));
  }
}

.mt-2 {
  margin-top: 20px !important;
}

.centered-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.pairing-view-toggle {
  position: absolute;
  right: 0;
}

#stepper {
  min-width: 500px;
  .heading {
    background-color: #469a88;
    color: white;
    border-radius: 0 0 50px 0;
    font-size: calc(min(3vw, 20px));
    padding: 5px;
    padding-left: 15px;
  }

  .content {
    .description {
      margin: 10px 0px;
    }

    .inputs {
      margin: 10px 0px;
    }

    .actions {
      margin: 20px 0px;
    }
  }

  .ml-sm {
    margin-left: 10px;
  }

  .ml-lg {
    margin-left: 30px;
  }

  .mt-xl {
    margin-top: 30px;
  }

  .flex-column {
    display: flex;
    flex-direction: column;
  }
  .save {
    margin-top: 20px;
  }
}

.tournament-row {
  .heading {
    background-color: #469a88;
    color: white;
    border-radius: 0 0 50px 0;
    font-size: calc(min(3vw, 20px));
    padding: 5px;
  }

  .content {
    display: grid;
    grid-auto-flow: column;
    gap: 30px;
    overflow-x: scroll;
    padding: 20px;
  }
}

.m-tb-1 {
  margin: 10px 0 !important;
}

.promotion-relegation-form {
  display: flex;
  flex-direction: column;
}

.dehaze {
  cursor: row-resize;
}
.dehaze:hover:focus {
  cursor: row-resize;
}
