.card-container {
  width: 375px;
  height: 600px;
  background-color: #ffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 2px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  position: relative;

  .content {
    padding: 2px 2px 10px 2px;
    max-height: 80%;
  }

  .actions {
    position: absolute;
    bottom: 20px;
    display: grid;
    grid-auto-flow: column;
    grid-gap: 10px;
  }

  .action {
    button {
      height: 40px;
      width: 150px;
      background-color: #375a7f;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      color: white;
      border-radius: 3px;
      font-size: 14px;
      line-height: 17px;
    }
  }
}
@media screen and (max-width: 1024px) {
    .card-container {
      width: 100% !important;
    .action {
      button {
        width: 130px;
      }
    }
  }
}

