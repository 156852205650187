body#inbox-page {
  background-color: #fafafa;
  color: black !important;
  font-family: Montserrat;
  #content-container {
    max-width: 1000px;
    .wrapper {
      margin-top: 60px;
      .header {
        color: #469a88;
        font-weight: bold;
        font-size: calc(min(6vw, 30px));
        font-style: normal;
        line-height: 44px;
      }

      .box {
        background: #fafafa;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        padding: 20px;
        margin-top: 10px;

        .info {
          display: flex;
          justify-content: space-between;
          .text {
          }
          .green {
            --icon-color: green;
          }
          .red {
            --icon-color: red;
          }
          .icon {
            background-color: var(--icon-color);
            width: 20px;
            height: 20px;
            border-radius: 10px;
          }
        }
        .body {
          margin-top: 10px;
        }
      }
    }
  }

  .bold {
    font-weight: bold;
  }

  .italic {
    font-style: italic;
  }
}
