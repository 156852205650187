body#league-build {
  font-family: Montserrat;
  #content-container {
    display: flex;
    justify-content: center;
  }

  main {
    width: calc(min(90vw, 1200px));
    margin-top: 20px;
  }
}
