.fide-info {
  .fide-input {
    margin: 20px 0;
  }

  .link {
    margin: 10px 0;
  }
}

form {
  .label {
    display: block;
  }

  .input-group {
    margin: 10px 0;
  }

  p {
    max-width: 650px;
  }
}

.first-info {
  display: flex;
  grid-gap: 30px;
  flex-direction: column;
}

.text {
  font-weight: bold;
  font-size: 20px;
}

.center {
  align-items: center;
}

.actions {
  display: flex;
  grid-gap: 10px;
}