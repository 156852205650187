#logo-container {
  display: flex;
  cursor: pointer;

  img {
    height: 50px;
  }

  span {
    font-size: 30px;
    padding-left: 5px;
    color: #131922;
  }
}