body#Login {
  #content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  form {
    display: flex;
    flex-direction: column;
  }

  input {
    width: 300px;
  }

  .modal-content {
    background-color: #fafafa;
  }
}
