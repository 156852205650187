.game-chat {
  width: 200px;
  height: 250px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  justify-content: flex-start !important;
  align-items: flex-start !important;

  .text-area {
    width: 100%;
    height: 85%;
    padding: 10px;
    overflow-x: hidden;
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start !important;
    align-items: flex-start !important;

    .item {
      margin-bottom: 5px;
    }
  }

  .message-area {
    width: 100%;
    height: 15%;
    border-top: 1px solid #e5e5e5;

    form {
      width: 100%;
      height: 100%;
    }
    input {
      width: 100%;
      height: 100%;
    }
  }
}
