
body#Account-Recover {
    background-color: #fafafa;
    color: black !important;
    font-family: Montserrat;
}

body#Account-Recover .nav-item {
    background-color: gray;
}

body#Account-Recover .table {
    color: black !important;
}

body#Account-Recover .table-striped tbody tr:nth-of-type(2n + 1) {
    background-color: #fafafa !important;
}