#Profile {
  background-color: #fafafa;
  color: black;
  .name-container {
    position: relative;
    display: flex;
    margin-top: 20px;
    justify-content: space-between;

    .info {
      display: flex;
      .image {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .settings {
      display: flex;
      align-items: center;
    }
  }

  #main-container {
    display: flex;
    justify-content: center;
    flex-direction: column;

    .nav-item {
      background-color: gray;
    }

    .table {
      color: black !important;
    }

    .table-striped tbody tr:nth-of-type(2n + 1) {
      background-color: #fafafa !important;
    }

    #content-container {
      max-width: 1200px;
    }
  }
  .username {
    font-size: calc(min(6vw, 45px));
    color: #469a88;
    margin: 5px 0;
    font-family: Montserrat;
    font-weight: bold;
    padding-left: 10px;
  }
  .header {
    font-size: calc(min(4vw, 30px));
    color: #469a88;
    margin: 5px 0;
    font-family: Montserrat;
    font-weight: bold;
    padding-left: 10px;
    margin-top: 20px;
  }

  .box {
    background: #fafafa;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 20px 20px 10px 20px;
    margin-top: 10px;
    .card-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin: 10px 5px;

      img {
        border-radius: 50%;
      }
      .text {
        margin-top: 5px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        color: #00bc8c;
      }
    }
    .table-header{
      font-size: 18px!important;
      font-weight: bold!important;
    }
    .table-cell{
      font-size: 16px;
    }
  }
}
@media screen and (max-width: 800px) {
  #Profile {
    .username {
      font-size: calc(min(8vw, 45px));
    }
    .header {
      font-size: calc(min(5vw, 30px));
    }
  }
}

