#Tournament-Players table input {
  width: 100%;
}

body#Tournament-Players {
  background-color: #fafafa;
  color: black !important;
  font-family: Montserrat;
}

body#Tournament-Players .nav-item {
  background-color: gray;
}

body#Tournament-Players .table {
  color: black !important;
}

body#Tournament-Players .add-team-popup {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

body#Tournament-Players .table-striped tbody tr:nth-of-type(2n + 1) {
  background-color: #fafafa !important;
}
