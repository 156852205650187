body#Tournament-Search {
  background-color: #fafafa;
  color: black !important;
  font-family: Montserrat;
}

body#Tournament-Search .nav-item {
  background-color: gray;
}

body#Tournament-Search .table {
  color: black !important;
}

body#Tournament-Search .table-striped tbody tr:nth-of-type(2n + 1) {
  background-color: #fafafa !important;
}