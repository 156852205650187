.countdown-container {
  display: flex;
  max-width: 1000px;
  justify-content: center;
  align-items: center;

  .item {
    margin: 2.5vw;
    color: #469A88;

    .name {
      font-size: calc(min(3vw, 22px));
      font-weight: 500;
    }

    .number {
      font-size: calc(min(3vw, 22px));
      font-weight: 500;
    }
  }
}
