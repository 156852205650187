#Game-Play {
  $board-height: calc(min(85vw, 80vh));
  .clock {
    font-size: 4vh;
  }
  .clock:not(.running) {
    opacity: 0.3;
  }
  .block-blur {
    .cg-wrap {
      pointer-events: none;
      filter: blur(5px);
    }
  }
  .block-blur-all {
    pointer-events: none;
    filter: blur(5px);
  }
  .circular-countdown-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .wrapper {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    .board-area {
      width: calc(min(85vw, 80vh)) !important;
      .play-box {
        padding-right: 1vh;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        .self-box {
          margin-top: 10px !important;
        }
        .user-box {
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 4vh;

          .user-info {
            display: flex;
            margin: 1vh 2vh;
            font-size: 2vh;
            align-items: center;

            .name {
              display: flex;
              margin-left: 1.5vh;
              white-space: nowrap;
            }

            img {
              height: 2vh;
            }
          }
        }
      }
    }
    .info-area {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      flex-grow: 1;
      flex-basis: 20%;
      z-index: 2;
      max-width: 300px;

      .tab-panel {
        .tab-panel-container {
          padding: 0px !important;
        }
        .tab-container {
          height: 400px;
          .MuiBox-root {
            padding: 0 !important;
          }
          .auto-promotion-container {
            .auto-promotion {
              display: flex;
              justify-content: center;
              align-items: center;
            }
            .choices {
              padding-left: 20px;
            }
          }
        }
      }

      .tournament-info {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        height: 40px;
      }

      .game-chat {
        width: 100%;
        height: 400px;
        box-shadow: none;
      }

      .buttons-container {
        display: flex;
        flex-direction: column;
        max-height: 30px;
        margin-bottom: 150px;
      }

      .decide-buttons {
        display: flex;
        justify-content: center;
        color: white;
        margin-top: 5px;
        a {
          margin-left: 20px;
        }
      }

      .action-button {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #375a7f;
        color: white;
        border: none;
        height: 40px;
        font-weight: 500;
        border-radius: 35px;
        cursor: pointer;
        width: 200px;
        margin: 10px;
      }
    }
    .png-area {
      margin-top: 10px;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      height: 40px;
      flex-grow: 1;
      flex-basis: 90%;
      display: flex;
      align-items: center;
      max-width: 90vw;
      overflow: scroll;
      z-index: 1;

      #move-div {
        display: flex;
        white-space: nowrap;

        .item {
          margin: 0 5px;

          span:nth-child(2) {
            margin: 0 2px;
          }

          .highlight-blue {
            padding: 5px;
            box-shadow: inset 0 0 2px 2px blue;
          }
        }
      }
    }
    .png-area::-webkit-scrollbar {
      display: none;
    }
  }

  background-color: #fafafa;
  color: black;

  #resign-btn,
  #draw-btn,
  #yes-draw-btn,
  #no-draw-btn {
    color: white;
  }

  .tournament-link {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .initial-countdown {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  #promotion-dialog img,
  #promotion-dialog span {
    cursor: pointer;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 10px;
  }

  #promotion-dialog img {
    width: 80px;
    height: 80px;
  }

  #promotion-dialog span {
    color: orangered;
    font-size: 18pt;
  }

  .btn-container {
    margin-left: 20px;
  }
  div.cg-wrap,
  cg-container {
    width: 90vh !important; /* Firefox mobile compat */
    width: $board-height !important;
    height: 90vw !important;
    height: $board-height !important;
  }
  #game-result-div {
    font-size: 24pt;
  }
  #white-name,
  #black-name {
    text-align: center;
    font-weight: bold;
    font-size: 1.1rem;
  }
  [data-square],
  [data-piece] {
    touch-action: none;
  }

  #outcome-popup .modal-content {
    background-color: #fafafa;
  }
}

@media screen and (max-width: 1200px) {
  .board-grid {
    justify-content: center !important;
  }
}

@media screen and (max-width: 500px) {
  #Game-Play {
    overflow-x: hidden;
    .wrapper {
      justify-content: center;
      align-items: center;
      .name {
        a {
          font-size: 17px;
        }
      }
      .tournament-info {
        margin-top: 25px;
        .action-button {
          margin-top: 8px;
          font-size: 17px;
        }
      }
      .game-chat {
        margin-top: 10px;
        width: 260px;
        input {
          font-style: italic;
        }
      }
      .tournament-link {
        font-size: 18px;
      }
    }
  }
}
