body#lobby-page {
  background-color: #fafafa;
  color: black !important;

  #main-container {
    .nav-item {
      background-color: gray;
    }

    .table {
      color: black !important;
    }

    .table-striped tbody tr:nth-of-type(2n + 1) {
      background-color: #fafafa !important;
    }
  }

  #content-container {
    max-width: 1000px;
    .header {
      color: #469a88;
      font-weight: bold;
      font-size: calc(min(6vw, 30px));
      font-style: normal;
      line-height: 44px;
      margin-top: 60px;
    }

    .box {
      background: #fafafa;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      padding: 20px;
      margin-top: 10px;
    }
  }
  .table-header{
    font-size: 18px!important;
    font-weight: 800!important;
    font-family: Montserrat !important;
  }
  .table-cell{
    font-size: 16px;
    font-family: Montserrat !important;
  }
}
@media screen and (max-width: 992px) {
  
}

