.wrapper {
  font-family: Montserrat;
  color: black;
  width: calc(min(90vw, 1200px));

  .round-starting-time {
    margin-top: 15px;
    font-weight: bold;
  }

  .header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 25px 0px 10px 0px;

    .header {
      color: #469a88;
      font-weight: bold;
      font-size: calc(min(6vw, 30px));
    }
    .icon {
      .tick {
        width: 20px;
      }
    }
  }

  .banner {
    position: relative;
    .pp {
      width: 15%;
      position: absolute;
      top: 65%;
      left: 42.5%;
    }
    .pp-custom {
      position: absolute;
      top: 65%;
      left: calc(min(40vw, 525px));
      height: calc(min(15vw, 150px));
      width: calc(min(15vw, 150px));
      border-radius: 50%;
    }

    .banner-pic {
      width: 100%;
      max-height: 300px;
      object-fit: cover;
    }
  }

  .name-container {
    position: relative;
    margin-top: calc(min(8vw, 80px));
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    .name {
      font-size: calc(min(5vw, 48px));
      font-weight: bold;
      line-height: calc(min(6vw, 50px));
      max-width: 800px;
    }
    .organiser {
      font-size: calc(min(3vw, 20px));
      margin-top: 10px;
      span {
        color: #469a88;
      }
    }
    .icon {
      position: absolute;
      top: 10px;
      right: 0;
      img {
        width: calc(min(5vw, 30px));
        cursor: pointer;
      }
    }
  }
  .description-container {
    display: flex;
    margin-top: calc(min(6vw, 60px));

    .about {
      .heading {
        font-size: calc(min(3vw, 20px));
        font-weight: 500;
        text-align: center;
      }

      font-size: calc(min(3vw, 20px));
    }
    .info {
      .item {
        .heading {
          font-weight: 500;
        }
        margin-bottom: 10px;
        height: 80px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .standings-container {
    color: black;
  }
}

.box {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 35px;
  background-color: #f4f4f4;
}

.centered-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.pairing-view-toggle {
  position: absolute;
  right: 0;
}

.action-button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
}

.absolute-right {
  position: absolute !important;
  right: 0;
  top: 10px;
}

.tournament-row {
  margin: 30px 0px 10px 0px;
  .heading {
    background-color: #469a88;
    color: white;
    border-radius: 0 0 50px 0;
    font-size: calc(min(3vw, 20px));
    padding: 5px;
    display: flex;
    justify-content: space-between;
    flex: row;
    .crosstable{
      margin-right: 40px;
      button{
        box-shadow: none;
        background-color: #78b7a9;
      }
    }
  }

  .content {
    display: grid;
    grid-auto-flow: column;
    gap: 30px;
    overflow-x: scroll;
    padding: 20px;
  }
}

.m-tb-1 {
  margin: 10px 0 !important;
}

.table-header{
  font-size: 18px!important;
  font-weight: 800!important;
  font-family: Montserrat!important;
}
.table-cell{
  font-size: 16px;
  a{
  color: #00bc8c;
  font-family: Montserrat!important;
  }
}
.player-title {
  background-color: #bf811d;
  color: white;
  padding: 1px 5px;
  border-radius: 5px;
  margin-right: 5px;
}
.tournament-name {
  font-weight: bold;
}
.crosstable-category {
  margin-top: 20px;
}