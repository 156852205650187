.button {
  background-color: #469a88;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  color: white;
  border: none;
  width: 80%;
  height: 40px;
  font-weight: 500;
  border-radius: 35px;
}

.backdrop {
  z-index: 1051;
}

.dialog {
  z-index: 1052;
}

.row {

  label {
    width: 150px;
  }

  .edit {
    margin-top: 10px;
  }
}

.header {
  font-size: 20px;
  margin: 20px 0;
}