.wrapper {
  font-family: Montserrat;
  color: black;
  width: calc(min(90vw, 1200px));
  .round-starting-time {
    margin-top: 15px;
    font-weight: bold;
  }

  .header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 25px 0px 10px 0px;

    .header {
      color: #469a88;
      font-weight: bold;
      font-size: calc(min(6vw, 30px));
    }
    .icon {
      .tick {
        width: 20px;
      }
    }
  }

  .banner {
    position: relative;
    .pp {
      width: 15%;
      position: absolute;
      top: 65%;
      left: 42.5%
    }
    .pp-custom {
      position: absolute;
      top: 65%;
      left: calc(min(40vw, 525px));
      height: calc(min(15vw, 150px));
      width: calc(min(15vw, 150px));
      border-radius: 50%;
    }

    .banner-pic {
      width: 100%;
      max-height: 300px;
      object-fit: cover;
    }
  }

  .name-container {
    position: relative;
    margin-top: calc(min(8vw, 80px));
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    .name {
      font-size: calc(min(5vw, 48px));
      font-weight: bold;
      line-height: calc(min(6vw, 50px));
    }
  }

  .description {
    font-size: calc(min(3vw, 25px));
  }
}

.box {
  background: #fafafa;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 20px 20px 10px 20px;
  margin-top: 10px;

  .card-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 10px 5px;

    img {
      border-radius: 50%;
    }
    .text {
      margin-top: 5px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      color: #00bc8c;
    }
  }
}

.centered-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.header {
  color: #469a88;
  font-weight: bold;
  font-size: calc(min(6vw, 30px));
}
