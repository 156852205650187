.hide {
  display: none !important;
}

.table {
  font-family: Montserrat !important;
}

.player-title {
  background-color: #bf811d;
  color: white;
  padding: 1px 5px;
  border-radius: 5px;
  margin-right: 5px;
}

.centered-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.standings-container {
  color: black;
}
.table-header {
  font-size: 18px;
  font-weight: bold;
}

.table-cell {
  font-size: 16px;
}
