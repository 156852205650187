#Tournament-View .title {
  background-color: #bf811d;
  color: white;
  padding: 1px 5px;
  border-radius: 5px;
}

#Tournament-View .dense td {
  padding-top: 0;
  padding-bottom: 0;
  border: none;
  vertical-align: middle;
}

#Tournament-View {
  background-color: #fafafa;
  color: black;

  .pairing-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .download {
      height: 40px;
    }
  }

  #main-container {
    .nav-item {
      background-color: gray;
    }

    .table {
      color: black !important;
    }

    .table-striped tbody tr:nth-of-type(2n + 1) {
      background-color: #fafafa !important;
    }
  }
  .list-group-item {
    background-color: #fafafa;
  }
}

#Tournament-View .modal-content {
  background-color: #fafafa;
  color: black;
}
