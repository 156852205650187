#setup-round-button {
  .time {
    margin-bottom: 15px;
  }
  button {
    margin-bottom: 15px;
    background-color: #469a88;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    color: white;
    border: none;
    width: 80px;
    height: 40px;
    font-weight: 500;
    border-radius: 35px;
  }
}
