.cg-wrap {
  background-image: url('https://drulpact.sirv.com/sp/brown.svg');
}

.cg-wrap piece.pawn.white {
  background-image: url('https://drulpact.sirv.com/sp/pieces/wP.svg');
}
.cg-wrap piece.bishop.white {
  background-image: url('https://drulpact.sirv.com/sp/pieces/wB.svg');
}
.cg-wrap piece.knight.white {
  background-image: url('https://drulpact.sirv.com/sp/pieces/wN.svg');
}
.cg-wrap piece.rook.white {
  background-image: url('https://drulpact.sirv.com/sp/pieces/wR.svg');
}
.cg-wrap piece.queen.white {
  background-image: url('https://drulpact.sirv.com/sp/pieces/wQ.svg');
}
.cg-wrap piece.king.white {
  background-image: url('https://drulpact.sirv.com/sp/pieces/wK.svg');
}
.cg-wrap piece.pawn.black {
  background-image: url('https://drulpact.sirv.com/sp/pieces/bP.svg');
}
.cg-wrap piece.bishop.black {
  background-image: url('https://drulpact.sirv.com/sp/pieces/bB.svg');
}
.cg-wrap piece.knight.black {
  background-image: url('https://drulpact.sirv.com/sp/pieces/bN.svg');
}
.cg-wrap piece.rook.black {
  background-image: url('https://drulpact.sirv.com/sp/pieces/bR.svg');
}
.cg-wrap piece.queen.black {
  background-image: url('https://drulpact.sirv.com/sp/pieces/bQ.svg');
}
.cg-wrap piece.king.black {
  background-image: url('https://drulpact.sirv.com/sp/pieces/bK.svg');
}