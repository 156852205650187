body#contact-page {
  background-color: #fafafa;
  color: black !important;
  font-family: Montserrat;
  #content-container {
    max-width: 1000px;
    .wrapper {
      margin-top: 60px;
      .header {
        color: #469a88;
        font-weight: bold;
        font-size: calc(min(6vw, 30px));
        font-style: normal;
        line-height: 44px;
      }

      .box {
        background: #fafafa;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        padding: 20px;
        margin-top: 10px;
      }
    }
  }
}

body#contact-page {
    background-color: #fafafa;
    color: black !important;
    font-family: Montserrat;
}

body#contact-page .nav-item {
    background-color: gray;
}

body#contact-page .table {
    color: black !important;
}

body#contact-page .table-striped tbody tr:nth-of-type(2n + 1) {
    background-color: #fafafa !important;
}
