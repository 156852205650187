#home {
  font-family: Montserrat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 100vw;
  overflow-x: hidden;
  #image {
    background-image: url("https://drulpact.sirv.com/Images/sp/homepage1-min.jpg");
    background-position: center center;
    background-size: cover;
    height: 450px;
    width: 100vw;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .pane {
      width: calc(min(40rem, 80%));
      box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2);
      border-radius: 15px;
      margin-right: 10vw;
      margin-left: 10vw;
      display: flex;
      align-items: center;
      overflow: hidden;
      background-color: rgba(255, 255, 255, 0.9);
      flex-direction: column;

      .title {
        font-size: calc(min(6vw, 25px));
        color: #469a88;
        font-weight: bold;
        text-align: center;
        padding: 10px;

        div:nth-of-type(1) {
          font-size: calc(min(7vw, 40px));
        }

        div:nth-of-type(2) {
          font-size: calc(min(4vw, 20px));
        }

        div:nth-of-type(3) {
          margin-top: 20px;
        }
      }

      .action-buttons {
        display: flex;
        width: 60%;
        margin: 20px 0;
        justify-content: space-between;

        .button {
          cursor: pointer;
          background-color: #469a88;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          border: none;
          width: 140px;
          height: 40px;
          color: white;
          border-radius: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }

  .block {
    max-width: 1000px;
    padding: 10px;
    width: 80%;
    .leaguewrapper {
      display: flex;
      overflow-x: scroll;
    }
    .emptyheading {
      background-color: #469a88;
      color: transparent;
      border-radius: 0 0 50px 0;
      font-size: calc(min(3vw, 20px));
      padding: 5px;
    }

    .heading {
      font-size: calc(min(6vw, 30px));
      color: #469a88;
      margin: 5px 0;
      font-family: Montserrat;
      font-weight: bold;
      a {
        color: white;
      }
      a:hover {
        color: white;
      }
    }

    .tournamentsHeadingWrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 20px;
      .seeAll {
        display: flex;
        align-items: center;
      }
    }

    .wrapper {
      background-color: white;
      .row {
        .heading {
          background-color: #469a88;
          color: white;
          border-radius: 0 0 50px 0;
          font-size: calc(min(3vw, 20px));
          padding: 5px;
        }
        .content {
          display: grid;
          grid-auto-flow: column;
          gap: 30px;
          overflow-x: scroll;
          padding: 20px;
        }
      }
    }
  }
}
@media screen and (max-width: 500px) {
  #home {
    background-color: white;
    #image {
      .pane {
        .action-buttons {
          width: 80%;
          .button {
            height: 40px;
            font-size: 14px;
            margin-right: 10px;
          }
        }
      }
    }
    .block {
      min-width: 100%;
      padding: 10px;
      width: 80%;

      .emptyheading {
        background-color: #469a88;
        color: transparent;
        border-radius: 0 0 50px 0;
        font-size: calc(min(3vw, 20px));
        padding: 5px;
      }

      .heading {
        a {
          font-size: 19px;
        }
        a:hover {
          font-size: 19px;
        }
      }
      .wrapper {
        .row {
          .heading {
            font-size: calc(min(3vw, 20px));
            padding: 5px;
          }
          .content {
            display: grid;
            grid-auto-flow: column;
            gap: 40px;
            overflow-x: scroll;
            padding: 20px;
          }
        }
      }
    }
  }
}
