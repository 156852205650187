#heading {
  font-size: calc(min(6vw, 30px));
  color: #469a88;
  margin-top: 10px;
  font-family: Montserrat;
  font-weight: bold;
}

.content {
  margin-top: 10px;
}

.action {
  margin-top: 10px !important;
}

.select {
  .inputs {
    margin: 10px 0px;
  }
}
